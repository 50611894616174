<template>
  <div class="sale-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "销售统计",
        search: [
          {
            key: "date",
            label: "日期范围",
            type: "daterange",
            timeType: 'yyyy-MM-dd'
          }
        ],
        columns: [
          {
            label: "日期",
            key: "statDate",
            on: row => {
              _this.$router.push({path: "/main/sale/detail/show/" + row.statDate, query:{
                  statDate: row.statDate,
                  totalOrder: row.totalOrder,
                  orderTotalFee: row.orderTotalFee,
                  oughtTotalFee: row.oughtTotalFee,
                  actualTotalFee: row.actualTotalFee,
                  accountTotalFee: row.accountTotalFee,
                  exceptionOrderFee: row.exceptionOrderFee,
                  exceptionOrderSum: row.exceptionOrderSum
                }
              })
            }
          },
          { label: "订单总数", key: "totalOrder" },
          { label: "订单总金额", key: "orderTotalFee" },
          { label: "应到账金额（元）", key: "oughtTotalFee" },
          { label: "实际到账金额（元）", key: "actualTotalFee" },
          { label: "账户总金额", key: "accountTotalFee" }
        ]
      }
    };
  },
  created() {
    console.log("sale-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit, 
        startDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
        endDate: opt.searchForm['date'] ? opt.searchForm['date'][1]: null
      };
      this.post("/finance-service/salesStatisticsDay/searchPageSalesStatisticsDay", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.dateStr = this.format(item.date);
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>